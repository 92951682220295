@import '../../styles/brand-colors';

.star-rating-modal-image {
    width: 100%;
    max-height: 203px;
}
.star-rating-modal-text {
    text-align: center;
    padding: 8px 48px;
}
.star-rating-modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.star-rating-modal-buttons > button {
    max-width: 50%;
    height: 48px;
}

.star-icon {
    width: 30px;
    height: 30px;
}

.star-rating-button-next {
    border-radius: 100px;
    text-align: center;
    font-family: Nunito Sans, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 0;
    height: 34px;
    text-transform: uppercase;
    color: $gray-two;
}
