@import '../../styles/brand-colors';

.banner-container {
    margin-top: 50px;
    border: 1px lightgray solid;
    border-radius: 4px;
    padding: 16px 24px 0px 24px;
}

.emergency-title {
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.5px;
}

.emergency-link {
    color: $lake;
    text-decoration: none;
    background-color: transparent;
    font-weight: bolder;
}

.type-body {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

@media only screen and (max-width: 600px) {
    .banner-container {
        margin-left: 0px;
        margin-right: 0px;
    }
}
