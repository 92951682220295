@import 'styles/brand-colors';
@import 'styles/font-styles';

/* Overwrite Bootstrap Modal header */
.modal-header {
    border: none;
    padding: 8px;
}

.hide-page {
    display: none;
}

#main-content {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
}

.clickable {
    cursor: pointer;
}

.loading {
    display: flex;
    justify-self: center;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
}

.loading > svg {
    height: 50px;
}

.hidden {
    display: none;
}

.body-regular {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.body-bold {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.body-small {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.body-small-bold {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.body-medium {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
}

.body-tiny {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
}

.body-xs {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
}

.body-xl {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
}

.body-bold-l {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
}

.body-bold-m {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
}

.body-bold-s {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
}

.body-bold-xs {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.heading-caps-small-light {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.heading-small-light {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 28px;
}

.heading-small {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 28px;
}

.heading-caps-small {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
}

.heading-med-light {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 28px !important;
    line-height: 32px;
}

.heading-large {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 48px;
}

.heading-xs {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
}

.heading-xxs {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
}

.heading-med {
    font-family: 'Public Sans' !important;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
}
