@import '../../styles/brand-colors';

#banner-download-app-main {
    background: $dusk-two;
    box-shadow: 0 4px 20px -10px rgba(109, 109, 109, 0.8);
    line-height: 16px;
    height: 95px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 9px;
    padding-right: 9px;
}
.banner-download-app {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5em 1rem;
}
.banner-download-app-image {
    margin-left: 10px;
}
.banner-download-app-message {
    margin-left: 10px;
    #banner-text-join {
        font-family: Wigrum, serif;
        font-size: 14px;
        line-height: 16px;
        color: $black;
        text-wrap: none;
    }

    #banner-text-note {
        font-family: Wigrum, serif;
        font-size: 12px;
        line-height: 14px;
        color: $dusk-lighter;
    }

    #banner-text-star {
        font-family: Wigrum, serif;
        font-size: 10px;
        line-height: 12px;
        color: $dusk-lighter;
    }
    @media (max-device-width: 640px) {
        #banner-text-join {
            font-size: 11px;
            line-height: 12px;
        }
        #banner-text-note {
            font-size: 9px;
            line-height: 14px;
            display: inline-block;
            width: 190px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        #banner-text-star {
            font-size: 10px;
            line-height: 12px;
        }
    }
    @media (max-device-width: 540px) {
        #banner-text-join {
            font-size: 11px;
            line-height: 12px;
        }

        #banner-text-note {
            font-size: 9px;
            line-height: 14px;
            display: inline-block;
            width: 160px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        #banner-text-star {
            font-size: 10px;
            line-height: 12px;
        }
    }
    @media (max-device-width: 320px) {
        #banner-text-join {
            font-size: 10px;
            line-height: 12px;
        }
        #banner-text-note {
            font-size: 9px;
            line-height: 14px;
            display: inline-block;
            width: 120px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        #banner-text-star {
            font-size: 10px;
            line-height: 12px;
        }
    }
    @media (max-device-width: 280px) {
        #banner-text-join {
            font-size: 10px;
            line-height: 12px;
            display: inline-block;
            width: 110px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        #banner-text-note {
            font-size: 9px;
            line-height: 14px;
            display: inline-block;
            width: 100px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }
}
.banner-download-app-open {
    margin-left: auto;
}
@media (max-device-width: 280px) {
    .image-app-banner {
        max-width: 45px;
        max-height: 45px;
    }
    .banner-button-open {
        font-size: 10px;
    }
}
.banner-button-open {
    border-radius: 100px;
    text-align: center;
    font-family: Nunito Sans, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 0;
    height: 34px;
    text-transform: uppercase;
    color: $lake-two;
}
.icon-banner {
    width: 14px;
    height: 14px;
}
