@import '../../styles/brand-colors';

.no-trips-found {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
}

.no-trips-found-enfasis {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    border-color: $white;
    box-shadow: 0 5px 20px -10px $card-shadow-color-1, 0px 5px 20px -10px $card-shadow-color-2;
}

.no-trips-found-image {
    margin-right: 32px;
    height: 209px;
    max-width: 30%;
}

.no-trips-found-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.no-trips-found-buttons > button {
    min-width: 160px;
    margin-right: 8px;
}

.panel-no-trips-founds {
    padding: 3rem !important;
    margin: 3rem !important;
}
@media screen and (max-width: 768px) {
    .no-trips-found {
        display: flex;
        flex-direction: column;
    }

    .no-trips-found-modal-text {
        text-align: center;
        letter-spacing: -0.5px;
        padding: 8px 48px;
    }

    .no-trips-found-modal-note {
        text-align: center;
        padding: 8px 48px;
    }

    .no-trips-found-modal-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .no-trips-found-image {
        margin: 0 auto;
        min-width: 200px;
        min-height: 200px;
    }
    .no-trips-found-buttons {
        display: flex;
        flex-direction: column;
    }
    .no-trips-found-buttons > button {
        width: 100%;
        margin-bottom: 8px;
    }
    .panel-no-trips-founds {
        padding: 0 !important;
        margin: 0 !important;
    }
}
