@import '../../styles/brand-colors';

.find-my-trip-modal-button {
    min-height: 50px;
}

.find-my-trip-modal-error {
    display: flex;
    flex-direction: row;

    margin-top: 8px;
    padding: 8px;
    border: 1px solid $dusk-ten;
    border-left: 4px solid $alertness;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 2px $dusk-ten;
}

.find-my-trip-modal-error-icon {
    fill: $dusk-light;
    max-height: 30px;
    max-width: 30px;
}

.find-my-trip-modal-error-message {
    margin-left: 8px;
    color: $dusk-light;
}
