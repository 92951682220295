@import '../../styles/brand-colors';

#error-modal {
    top: 100px;
    box-shadow: 0 2px 4px $shadow-color, 0 0 1px $shadow-color;
}

.error-modal-body {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
}

.error-modal-image {
    max-width: 100px;
}

.error-modal-instructions {
    width: 100%;
    text-align: center;
}

.error-flow-id {
    font-size: small;
    text-align: right;
    font-family: monospace;
}
