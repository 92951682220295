@import '../../styles/brand-colors';

.trips-row {
    margin-bottom: 16px;
}

.add-trip-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $midnight-30;
    min-height: 320px;
}

.add-trip-box > button {
    margin-top: 16px;
    border-radius: 50px;
    min-width: 160px;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 3;
}
