@import '../../styles/brand-colors';

@mixin btn($bg, $color, $border, $hover, $active: $hover) {
    color: $color;
    background-color: $bg;
    border-color: $border;
    box-shadow: inset 0 1px 4px rgba($info, 0), 0 0 0 3px rgba($default, 0);
    padding: 8px 15px;
    min-width: fit-content;

    &:hover,
    &:focus,
    &:active {
        background-color: $hover !important;
        color: $black;
        border-color: $border !important;

        @if ($border == $bg) {
            border-color: $hover !important;
        }
    }

    &:disabled {
        color: $color;
        background-color: $bg;
        border-color: $border;
        opacity: 0.4;
        pointer-events: none;
    }
}

.vacasa-btn-app-banner {
    @include btn($gray-lighter-other, $info, transparent, $info-4);
}

.vacasa-btn-default {
    @include btn($white, $info, transparent, $info-4);
}

.vacasa-btn-primary {
    @include btn($primary, $black, $primary, $primary-70, $guest-yellow);
}

.vacasa-btn-info {
    @include btn($white, $secondary, $secondary, $info-4);
}

.vacasa-btn-danger {
    @include btn($white, $alertness, $alertness, $alertness-10);
}

.loader-dots {
    margin-top: -24px;
    font-size: 0;
    text-align: center;
}

.loader-dots > div {
    width: 16px;
    height: 16px;
    background-color: $lake;
    opacity: 0.2;
    margin: 0 4px;

    border-radius: 8px;
    display: inline-block;

    -webkit-animation: sk-bouncedelay 0.8s infinite ease-in-out both;
    animation: sk-bouncedelay 0.8s infinite ease-in-out both;
}

.loader-dots .bounce1 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.loader-dots .bounce2 {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0.8);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        /* background-color: #0078AB;
          background-color: #FFAD02; */
    }
}

.loader-dots--sm > div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
}

.loader-dots--dusk > div {
    background-color: $gray-one;
}

.loader-dots--midnight > div {
    background-color: $midnight;
}
