.main-title {
    font-family: 'Calluna' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 51px;
    line-height: 56px;
}

#grid-current-trips {
    padding-right: 15px;
    margin-bottom: -50px !important;
}

#grid-upcoming-trips {
    margin-bottom: -48px;
}

.separator-info-panel {
    margin-bottom: 48px;
}

.separator-current-info-panel {
    margin-bottom: 72px;
}

.separator-banner-title {
    margin-bottom: 96px;
}

.separator-no-trips-found {
    margin-bottom: 96px;
}

@media screen and (max-width: 768px) {
    .separator-info-panel {
        margin-bottom: 0;
    }

    .separator-current-info-panel {
        margin-bottom: 24px;
    }

    .separator-banner-title {
        margin-bottom: 16px;
    }

    .main-title {
        font-size: 21px;
        font-weight: 500;
        height: 28px;
        letter-spacing: -0.2px;
        line-height: 28px;
    }
}
