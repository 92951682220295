@import '../../styles/brand-colors';

.img-medium {
    height: 136px;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.img-small {
    height: 103px;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.card-body {
    padding: 15px;
}

.card-date {
    color: $card-gray-title;
    height: 24px;
    letter-spacing: 0.38px;
    text-transform: uppercase;
    width: 158px;
}

.card-location {
    color: $black;
    height: 24px;
    letter-spacing: 0;
}

.card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-title-past-trip {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-bg-img {
    right: 0;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.card-style {
    border-color: $white;
    box-shadow: 0px 5px 20px -10px $card-shadow-color-1, 0px 5px 20px -10px $card-shadow-color-2;
}

.current-trip-grid {
    padding-right: 0px;
}

.current-trip-card {
    border-right: white;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.current-trip-image {
    margin-right: 15px;
}

@media only screen and (max-width: 768px) {
    .current-trip-card {
        border-color: $white;
        box-shadow: 0px 5px 20px -10px $card-shadow-color-1, 0px 5px 20px -10px $card-shadow-color-2;
    }
}
