.need-help-modal-image {
    width: 100%;
    max-height: 203px;
}
.need-help-modal-text {
    text-align: center;
    padding: 8px 48px;
}

.need-help-modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* Only Firefox */
    @-moz-document url-prefix() {
        margin-bottom: 16px;
    }
    /* End Only Firefox */
}

.need-help-modal-buttons > button {
    flex-grow: 1;
    margin-left: 2px;
    margin-right: 0;
    max-width: 80%;
}
