.loader-dots-main {
    position: absolute;
    top: 50%;
    left: 50%;
}

.loader-home {
    margin-top: 50%;
    margin-left: 50%;
}
