@import '../../styles/brand-colors';

.card-stay-panel-footer {
    margin: 0px 2px 3px 2px;
    min-height: 29px;
    padding: 0px;
    background-color: $midnight-4;
    border-color: $midnight-4;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.card-stay-panel-footer-content {
    padding-left: 0;
    margin: 4px 0 0 0;
    color: $gray-one;
    letter-spacing: 0;
}

.card-stay-panel-footer-left-icon {
    max-height: 40px;
    max-width: 25px;
    margin-left: 12px;
    stroke-width: 7px;
    stroke: $lake;
}

.card-stay-panel-star-icon,
.card-stay-panel-calendar-icon {
    max-height: 40px;
    max-width: 25px;
    margin-left: 12px;
    fill: $lake;
}

.card-stay-panel-footer-right-icon {
    max-height: 18px;
    max-width: 17.5px;
    margin-left: 5px;
    stroke: black;
    stroke-width: 7px;
}

.card-stay-panel-check-icon {
    fill: $success;
    stroke: $success;
    stroke-width: 10px;
}
