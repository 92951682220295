@import '../../../styles/brand-colors';

$grid-gutter-width: 16px;
$nav-button-color: $midnight;
$border-color: $midnight;
$border-radius: 2px;
$arrow-color: $dusk-lightest;
$hover-fill: $midnight;
$label-color: $midnight;

$height: 56px;
$height-sm: 56px;

$shadow-color: rgba(0, 0, 0, 0.08);
$shadow-color-2: rgba(0, 0, 0, 0.2);
$shadow-color-3: rgba(0, 0, 0, 0.15);

$border-color: $vacasa-yorange;

$input-fill: $dusk-four;
$input-color: $midnight-100;

$divider-color: $gray-six;
$button-border: $midnight;
$svg-color: $midnight-60;
$label-color: $midnight;

$arrow-color: $dusk-light;

@mixin rounded($radius: $border-radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin prefixed($prop, $args...) {
    -webkit-#{$prop}: $args;
    -moz-#{$prop}: $args;
    -ms-#{$prop}: $args;
    -o-#{$prop}: $args;
    #{$prop}: $args;
}

@mixin transition($args...) {
    @include prefixed(transition, $args);
}

@mixin breakpoint($min: 0, $max: 0) {
    $type: type-of($min);

    @if $type == string {
        $class: $min;

        @if $class == xs {
            @media (max-width: 576px) {
                @content;
            }
        } @else if $class == sm {
            @media (min-width: 768px) {
                @content;
            }
        } @else if $class == md {
            @media (min-width: 992px) {
                @content;
            }
        } @else if $class == lg {
            @media (min-width: 1200px) {
                @content;
            }
        } @else {
            @warn "Breakpoint mixin supports: xs, sm, md, lg";
        }
    } @else if $type == number {
        $query: 'all' !default;
        @if $min != 0 and $max != 0 {
            $query: '(min-width: #{$min}) and (max-width: #{$max})';
        } @else if $min != 0 and $max == 0 {
            $query: '(min-width: #{$min})';
        } @else if $min == 0 and $max != 0 {
            $query: '(max-width: #{$max})';
        }
        @media #{$query} {
            @content;
        }
    }
}

.language-currency-modal {
    margin-right: $grid-gutter-width;
    font-size: 13px;
    etter-spacing: 0.3px;
    line-height: 16px;

    // TODO: should this be a standard breakpoint?
    @include breakpoint(0, 1024px) {
        margin-right: 5px;
    }

    .box {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;
        background-color: $nav-button-color;
        white-space: nowrap;
        border: 1px solid $border-color;
        @include rounded();
        height: 24px;
        width: min-content;
        cursor: pointer;

        @include transition(ease 0.2s background-color);

        @include breakpoint(0, 1024px) {
            margin-right: 10px;
            margin-left: 10px;
        }

        .language {
            // language label
            margin: 0 4px;
            // TNR-789 - fix navbar wrapping at ~ipad size
            @include breakpoint(768px, 995px) {
                max-width: 100px;
                -ms-text-overflow: ellipsis;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .icon-globe {
            margin-left: 4px;
            font-size: 18px;
        }

        span {
            // currency code
            align-self: stretch; // stretch so that border line extends to container edge
            margin: 0 4px 0 2px;
            padding: 4px 0 0 4px;
            border-left: 1px solid $arrow-color;
            @include transition(ease 0.2s background-color, ease 0.2s box-shadow);

            @include breakpoint(0, 1024px) {
                @include transition(ease box-shadow 0.2s, ease background-color 0.2s);
            }
        }

        &:hover {
            background-color: $hover-fill;
        }
    }
}

#footer {
    .language-currency-modal {
        .box {
            height: 30px;

            span {
                padding-top: 7px;
            }
        }
    }
}

li.show .menu-arrow {
    display: block;
}

.dropdown-menu {
    box-shadow: 0 -5px 25px $shadow-color-3, 0 6px 8px $shadow-color-2;
    //@include box-shadow(2px, 2px, 15px, 0, #000);
    border: none;

    &.dropdown-menu-left {
        top: 42px;
        right: -16px;
        left: auto;
    }

    .dropdown-item {
        // total height should be 32px
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 4px 8px;
        min-width: 260px;
        cursor: pointer;

        .svg-icon {
            font-size: 20px;
            padding-right: 8px;
        }

        span {
            font-size: 16px;

            &.btn-link {
                // phone numbers
                padding-left: 4px;
            }
        }
    }
}

.menu-arrow {
    display: none;
    position: absolute;
    z-index: 1001;
    bottom: -8px;
    right: -5px;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: $white;
    border-width: 12px;
}

.nav-font-mobile {
    font-family: 'Public Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.5;
    color: #2c3439;
    letter-spacing: 0.3px;
}

.nav-font {
    font-family: 'Public Sans', sans-serif !important;
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
}

#navbar-user {
    display: flex;
    width: 100%;
    align-items: stretch;
    background: $white;
    -webkit-font-smoothing: antialiased;

    .account-name {
        text-overflow: ellipsis;
        max-width: 20ch;
        overflow: hidden;
        white-space: nowrap;
    }

    #navbar-main {
        height: 56px !important;
        line-height: 1.5;
        white-space: nowrap;
        padding-left: 8px;
        padding-right: 8px;

        @include breakpoint(xs) {
            padding-left: 16px;
            padding-right: 16px;
        }

        .navbar-brand-v2 {
            margin-right: 0;

            @include breakpoint(md) {
                margin-right: 24px;
            }

            .horizontal-logo-v2 {
                width: 120px;
                height: 34px;
            }
        }

        .navbar-nav {
            height: 19px;
            .nav-link-v2 {
                color: $label-color;
                font-size: 13px;
                font-weight: 500;
                cursor: pointer;
                padding: 0;
                margin-right: 24px;

                span {
                    height: 16px;
                    font-size: 13px;
                }

                .feather {
                    height: 16px;
                    width: 16px;
                    stroke: $svg-color;
                }
            }

            .nav-link-v2:hover {
                text-decoration: none;
            }

            .nav-item-v2 {
                margin-right: 24px;
                margin-left: 24px;

                .dropdown-menu {
                    font-size: 13px;
                    font-weight: 500;
                    min-width: 200px;
                    padding: 8px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border-radius: 4px;
                    box-shadow: 0 2px 4px $shadow-color-2, 0 0 1px $shadow-color-2;

                    .dropdown-item {
                        font-size: 13px;
                        font-weight: 500;
                        align-items: center;
                        height: 32px;
                        color: $label-color;
                        -webkit-border-radius: 2px;
                        -moz-border-radius: 2px;
                        border-radius: 4px;
                        min-width: 100px;

                        span {
                            height: 16px;
                            font-size: 13px;
                            margin-left: 8px;
                        }

                        .feather {
                            height: 16px;
                            width: 16px;
                            stroke: $svg-color;
                        }

                        &:hover {
                            background: $input-fill;
                        }
                    }

                    .dropdown-divider {
                        border-top: 1px solid $gray-six;
                        margin-left: -8px;
                        margin-right: -8px;
                    }

                    .pill a {
                        line-height: 20px;
                        @include rounded(14px);
                        height: 28px;
                        font-size: 13px;

                        &:hover {
                            background-color: $guest-yellow;
                        }
                    }
                }

                .dropdown-menu.dropdown-menu-left {
                    top: 32px;
                    right: -32px;
                }

                .dropdown-menu:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 70px;
                    box-sizing: border-box;
                    border: 8px solid $black;
                    border-color: $white $white transparent transparent;
                    transform-origin: 0 0;
                    transform: rotate(-45deg);
                    box-shadow: 1px -0.5px 0px 0 $shadow-color-2;
                }

                .dropdown-toggle::after {
                    display: none;
                }
            }
        }

        .navbar-toggler {
            padding: 0;

            .feather {
                height: 24px;
                width: 24px;
                stroke: none;
                fill: #2c3439;
            }

            .feather.inactive {
                display: none;
            }
        }

        .navbar-toggler.hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    #currency-link-mobile,
    #language-link-mobile {
        border: none;
        background: white;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .language-currency-dropdown {
        margin-left: 9px;
        color: $midnight;

        select {
            float: left;
            color: $midnight;
        }

        .feather {
            width: 16px;
            height: 16px;
            margin-top: 12px;
            margin-left: 0 !important;
        }
    }

    #language_hidden_select,
    #currency_hidden_select {
        display: none;
    }

    .sidenavbar {
        transition: all 0.2s;
        background: $white;
        height: 100%;
        max-width: 100vw;
        min-width: 100vw;
        margin-top: $height;
        transform: translateX(0%);
        overflow-y: auto;
        padding-bottom: 60px;

        .sidenavbar-content {
            padding: 24px 8px 16px;
            display: flex;
            flex-direction: column;

            .sidenavbar-item {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .feather {
                    height: 16px;
                    width: 16px;
                    stroke: $svg-color;
                }

                span {
                    font-family: 'Public Sans', sans-serif !important;
                    font-style: normal;
                    letter-spacing: 0.3px;
                    /* color: $text-color; */
                    color: $input-color;
                    margin-left: 8px;
                }
            }

            .sidenavbar-select-border {
                border-bottom: 0.5px solid rgba(0, 51, 73, 0.5);
                width: 90%;
                padding-bottom: 5px;
            }

            .sidenavbar-select {
                width: 90%;
                background: none !important;
            }

            .pill a {
                @include rounded(24px);
                font-size: 13px;
                letter-spacing: 0.3px;
                line-height: 48px;
                height: 48px;
            }

            .sidenavbar-item:hover {
                background: $input-fill;
                text-decoration: none;
            }

            .sidenavbar-divider {
                margin: 16px -8px;
                border-top: 1px solid $divider-color;
            }
        }

        &#sidenavbar-right {
            left: auto;
        }

        &.left-inactive {
            transform: translateX(-100%);
        }

        &.right-inactive {
            transform: translateX(100%);
        }
    }

    .feather-dark {
        fill: $svg-color;
    }

    .pill {
        padding: 10px;

        a {
            display: block !important;
            background-color: $owner-yellow;
            font-weight: 500;
            text-align: center;
            /* color: map_get($vacasa-colors, 'midnight'); */
            color: $midnight;

            &:hover {
                background-color: $guest-yellow;
            }
        }
    }

    .no-scroll {
        overflow-y: hidden;
    }
}

.pill {
    line-height: 36px;
}

#navbar-user {
    #navbar-main {
        .navbar-nav {
            .nav-item {
                .dropdown-menu.dropdown-menu-left {
                    right: -17px;

                    .dropdown-currency-container {
                        max-height: 300px;
                        overflow-y: auto;
                    }
                }

                .dropdown-menu:after {
                    right: 16px;
                }
            }
        }
    }
}

body.template-placepage {
    #navbar-main {
        .nav-item {
            .my-vacasa-menu.dropdown-menu.dropdown-menu-left {
                right: -12px !important;
            }
        }
    }
}

.fix-language {
    padding-top: 9px !important;
}

.fix-right-nav {
    margin-top: 2px;
}

.fix-pad {
    padding-bottom: 0;
    padding-top: 0;
}

.icon-caret-down-nav-v2 {
    stroke-width: 2;
    margin-left: 4px;
    margin-top: 6px;
}

.icon-head-set {
    height: 16px;
    width: 16px;
}

.icon-globe-nav {
    height: 16px;
    width: 16px;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.icon-user-nav {
    height: 24px;
    width: 24px;
}

.ml-32,
.mx-32 {
    margin-left: 2rem !important;
}

.mr-32 {
    margin-right: 2rem !important;
}

#left-icon-menu {
    height: 20px !important;
    width: 20px !important;
}

#right-icon-user {
    fill: $midnight;
}

#left-icon-close {
    stroke-width: 0;
    stroke: $midnight;
    fill: $midnight;
    left: 0;
}

@include breakpoint(xs) {
    .no-scroll {
        overflow: hidden;
        height: 100%;
    }
}

.hidden-icon {
    visibility: hidden;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.remove-padding {
    padding: 0 !important;
}

.border-divider {
    border-top: 1px solid #e6ebed;
}

.light-stroke {
    stroke-width: 0;
}

.user-mobile-bg {
    background-color: $midnight-2;
}

.hamburger-menu-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

#mobile-notifications {
    margin-left: 8px;
    align-self: center;
}

/* Only Safari 7.1+  */
_::-webkit-full-page-media,
_:future,
:root .mobile-safari {
    bottom: 8px;
}

/* End Only Safari 7.1+  */

/* Only Firefox */
@-moz-document url-prefix() {
    .mobile-firefox {
        bottom: 3px;
    }
}

/* End Only Firefox */

.mobile-notification-margin {
    margin-right: 20px;
}

#language-link .globe {
    padding-bottom: -6px;
}

[tabindex='0'],
a {
    outline: none;
}

.globe {
    fill: $midnight-60;
    stroke-width: 0;
    margin-bottom: 2px;
    margin-top: -3px;
    margin-right: 4px !important;
}

.font-small {
    font-size: small;
}

.nav-item-attention {
    border-radius: 4px;
}

#navbar-main .navbar-nav .nav-item .dropdown-menu .dropdown-item.nav-item-attention,
#navbar-user #navbar-main .navbar-nav .nav-item .dropdown-menu .dropdown-item.nav-item-attention {
    font-weight: 500;
    background-color: $guest-yellow-20;
}

#navbar-main .navbar-nav .nav-item .dropdown-menu .dropdown-item.nav-item-attention:hover,
#navbar-user #navbar-main .navbar-nav .nav-item .dropdown-menu .dropdown-item.nav-item-attention:hover {
    background-color: $guest-yellow-40;
}

#navbar-user .sidenavbar .sidenavbar-content .sidenavbar-item.nav-item-attention {
    font-weight: 500;
    background-color: $guest-yellow-20;
}

#navbar-user .sidenavbar .sidenavbar-content .sidenavbar-item.nav-item-attention:hover {
    background-color: $guest-yellow-40;
}

.ml-32,
.mx-32 {
    margin-left: 32px !important;
}

.mr-32,
.mx-32 {
    margin-right: 32px !important;
}
