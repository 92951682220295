@import '../../styles/brand-colors';

.card-stay-panel-body {
    padding: 0;
}

.card-stay-panel-container {
    padding: 5px 15px 0px 15px;
}

.divider-arrow {
    width: 22px;
    max-height: 80%;
}

.check-title {
    text-transform: uppercase;
    color: $gray-two;
    letter-spacing: 1px;
}

.check-date {
    font-size: large;
}

.card-stay-check-date {
    margin: 2px 0 0 0;
    color: $gray-one;
    letter-spacing: 0;
    /* Overflow */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card-stay-check-time {
    margin: 2px 0px 0px 0px;
}

.card-row {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.right-content-margin {
    margin-left: 8px;
}
