@import '../../styles/brand-colors';
.image-panel {
    width: 365px;
    max-height: 100%;
}

.text-panel {
    align-self: center;
}

.button-close-panel {
    float: right;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    color: $black;
    text-shadow: 0 1px 0 $white;
    opacity: 0.5;
    flex-direction: row-reverse;
}
.modal-header .button-close-panel {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

@media (max-width: 768px) {
    #info-panel-top {
        display: none !important;
    }
}
